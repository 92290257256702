import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";import { Card,CardHeader, CardBody, Col, Container, Input, Label, Row, Table,Button,Modal, ModalBody, ModalHeader,ModalFooter, Spinner} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
// import { Modal, Spinner } from 'react-bootstrap';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
//import { BasicTable, ScrollVertical, ScrollHorizontal, AlternativePagination, FixedHeaderDatatables, ModalDataDatatables, AjaxDatatables } from '../Tables/DataTables/datatableCom';
import { useQuery,useMutation, gql } from '@apollo/client';
// import checkTokenValidity from '../../Layouts/Footer'
import DataTable from 'react-data-table-component';
// import { tileBoxs1, tileBoxs2, tileBoxs3, tileBoxes4, tileBoxes5 } from "../../common/data/index";
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RecentActivity } from './recentActivity';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // Replace with your actual token
  const token = localStorage.getItem("token");

  // Add custom headers
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem("token"),
    },
  };
});
// Initialize Apollo Client with custom headers
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // cache: 'no-cache'
  cache: new InMemoryCache(),
});
const DESCENDANTS_QUERY = gql`
query MyQuery($id: Int!) {
  operators_by_pk(id: $id) {
id
  }
}`;

const ALL_QUERY = gql`
query MyQuery {
  shops(where: {operator_id: {_in: $_in}, is_deleted: {_eq: false}}) {
    id
  }
  users(where: {shop: {operator_id: {_in: $_in}}, is_deleted: {_eq: false}}) {
    id
  }
  operators(where: {id: {_in: $_in}, is_deleted: {_eq: false}}) {
    id
  }
}
`;

const COUNTERS_QUERY = gql`
query GetCounts {
  serviceProvidersCount: service_providers_aggregate {
    aggregate {
      count
    }
  }
  servicesCount: services_aggregate {
    aggregate {
      count
    }
  }
  usersCount: users_aggregate {
    aggregate {
      count
    }
  }
  appointmentsCount: appointments_aggregate {
    aggregate {
      count
    }
  }
}
`;

const APPOINTMENTS_QUERY = gql`
query GetAppointments {
  appointments(order_by: { created_at: desc }) {
    id
    created_at
    start_time
    end_time
    status
    user {
      id
      username
    }
    appointment_to_staff_to_service_relationship {
      staff_to_service_relationship {
        service {
          id
          name
          price
          service_provider {
            id
            username
          }
        }
      }
    }
  }
}
`;

const FILTERED_TRANSACTIONS_QUERY = gql`
query FilteredTransactionsQuery($operatorIds: [Int!], $shopIds: [Int!], $userIds: [Int!]) {
  transactions(
    where: {_or: [{_and: [{from_id: {_in: $operatorIds}}, {from_type: {_eq: "operator"}}]}, {_and: [{to_id: {_in: $operatorIds}}, {to_type: {_eq: "operator"}}]}, {_and: [{from_id: {_in: $shopIds}}, {from_type: {_eq: "shop"}}]}, {_and: [{to_id: {_in: $shopIds}}, {to_type: {_eq: "shop"}}]}, {_and: [{from_id: {_in: $userIds}}, {from_type: {_eq: "user"}}]}, {_and: [{to_id: {_in: $userIds}}, {to_type: {_eq: "user"}}]}]}
    order_by: {created_at: desc}
    limit: 10000
  ) {
    amount
    created_at
    from_id
    from_type
    from_username
    id
    to_id
    to_type
    to_username
    is_out
  }
}

`;

const FILTERED_LOGS_QUERY = gql`
query FilteredTransactionsQuery($operatorIds: [Int!], $shopIds: [Int!], $userIds: [Int!]) {
  logs(
    where: {
      _or: [
        { _and: [{ belongs_to_id: { _in: $operatorIds } }, { belongs_to_type: { _eq: "operator" } }] },
        { _and: [{ affected_id: { _in: $operatorIds } }, { affected_type: { _eq: "operator" } }] },
        { _and: [{ belongs_to_id: { _in: $shopIds } }, { belongs_to_type: { _eq: "shop" } }] },
        { _and: [{ affected_id: { _in: $shopIds } }, { affected_type: { _eq: "shop" } }] },
        { _and: [{ belongs_to_id: { _in: $userIds } }, { belongs_to_type: { _eq: "user" } }] },
        { _and: [{ affected_id: { _in: $userIds } }, { affected_type: { _eq: "user" } }] },
      ]
    },
    order_by: { created_at: desc }
    limit: 30
  ) {
    affected_type
    affected_id
    belongs_to_type
    belongs_to_id
    description
    is_success
    created_at
    id
  }
}

`;



let theNotSelected=[]
let theFilteredData=[]
let initialRows=[]

const SEARCH_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'ServiceProviders', label: 'Service Providers' },
  // { value: 'Services', label: 'Services' },
  { value: 'Users', label: 'Users' },

];

const SEARCH_QUERIES = {
  // option1: gql`
  //   query SearchOption1($searchTerm: String!) {
  //     // your GraphQL query for option 1
  //   }
  // `,
  // option2: gql`
  //   query SearchOption2($searchTerm: String!) {
  //     // your GraphQL query for option 2
  //   }
  // `,
  // option3: gql`
  //   query SearchOption3($searchTerm: String!) {
  //     // your GraphQL query for option 3
  //   }
  // `,
  // option4: gql`
  //   query SearchOption4($searchTerm: String!) {
  //     // your GraphQL query for option 4
  //   }
  // `,
};

const tileBoxs1 = [
  {
      id: 1,
      label: "Providers",
      labelClass: "muted",
      // percentage: "+16.24 %",
      // percentageClass: "success",
      // percentageIcon: "ri-arrow-right-up-line",
      counter: "0",
      caption: "View all",
      icon: "ri-file-user-line",
      iconClass: "success",
      decimals: 0,
      // prefix: "$",
      // suffix: "k",
  },
  {
      id: 2,
      label: "Services",
      // labelClass: "white-50",
      labelClass: "white",
      // percentage: "-3.57 %",
      // percentageClass: "warning",
      // percentageIcon: "ri-arrow-right-down-line",
      counter: "0",
      caption: ".",
      icon: "ri-shopping-cart-2-fill",
      iconClass: "light",
      bgColor: "bg-primary",
      counterClass: "text-white",
      captionClass: "text-white-50",
      decimals: 0,
      prefix: "",
      // separator: ",",
      suffix: "",
  },
  {
      id: 3,
      label: "Users",
      labelClass: "white",
      // percentage: "+29.08 %",
      // percentageClass: "success",
      // percentageIcon: "ri-arrow-right-up-line",
      bgColor: "bg-info",
      counterClass: "text-white",

      counter: "0",
      caption: "View all",
      icon: "ri-record-circle-line",
      iconClass: "warning",
      decimals: 0,
      prefix: "",
      // suffix: "M",
  },
  {
      id: 4,
      label: "Appointments",
      labelClass: "white",
      // percentage: "+0.00 %",
      // percentageClass: "muted",
      bgColor: "bg-danger",
      counterClass: "text-white",

      counter: "0",
      caption: ".",
      icon: "bx bx-wallet",
      iconClass: "primary",
      decimals: 0,
      // prefix: "$",
      // separator: ",",
      // suffix: "k",
  },
];

function transformTransactions(transactions) {
  return transactions.map(transaction => {
    const from =  transaction.from_username ;
    const fromType =  transaction.from_type ;
    const fromID =  transaction.from_id ;

    const to =  transaction.to_username;
    const toType =  transaction.to_type ;
    const toID =  transaction.to_id ;

    const isIn = !transaction.is_out ? transaction.amount : 0;
    const isOut = transaction.is_out ? transaction.amount : 0;
    const date = transaction.created_at;
    return { from,fromType,fromID, to,toType,toID, isIn, isOut, date };
  });
}

const actionObj={1:"Enable Panic ",
2:"Disable Panic",
3:"Enable User",
4:"Disable User"}

const Starter = () => {
  const { t } = useTranslation();
document.title ="Home";

// const { dataUSERS,loadingUSERS,errorUSERS } = useQuery(USER_QUERY);
const [rows, setRows] = useState([])
const [rowsSearch, setRowsSearch] = useState([])
const [descendantsList, setDescendantsList] = useState([])

const [isLoading, setIsLoading] = useState(true)
const [selectedOption, setSelectedOption] = useState('all');
const [filterValue, setFilterValue] = useState('');
const [sortState, setSortState] = useState({});
const [isLoadingModal, setIsLoadingModal] = useState(false);
const [isModalOpen, setIsModalOpen] = useState(false);
const [searchMode, setSearchMode] = useState(false);
const [recentActivity, setRecentActivity] = useState([]);
const [opTimezone, setopTimezone] = useState('UTC');
const [appointments, setAppointments] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

// I have 3 graphql tables: operators, shops and users. All of them have a string attribute "username". I would
// const [searchTerm, setSearchTerm] = useState('');
// const [selectedOption, setSelectedOption] = useState(SEARCH_OPTIONS[0].value);

async function searchByUsername(keyword, table = 'all') {
  await setRowsSearch([])
  const attributes="{ id username }"
  const query = `
    query {
      ${table === 'all' || table === 'Users' ? 'users(where: {username: {_ilike: "%'+keyword+'%"}})'+attributes : ''}

      ${table === 'all' || table === 'ServiceProviders' ? 'service_providers(where: { username: {_ilike: "%'+keyword+'%"}})'+attributes : ''}


    }
  `;

  const token = localStorage.getItem("token");

  const GraphQLrequestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'authorization': localStorage.getItem("token") },
    body: JSON.stringify({
      query
    })
  };
  
  const GraphQLresponse = await fetch(process.env.REACT_APP_API_URL, GraphQLrequestOptions)
  if(GraphQLresponse.status!=200){
    alert("Could not fetch data", GraphQLresponse.statusText)
    return
  }
  
  const GraphQ_json = await GraphQLresponse.json();
  console.log("ta dataa search:",GraphQ_json)

  const obj= await GraphQ_json.data;
  
  
  const newObj = {
    users: obj.users!=undefined?obj.users.map(user => ({ ...user, role: 'User' })):[],
    service_providers: obj.service_providers!=undefined?obj.service_providers.map(service_provider => ({ ...service_provider, role: 'Service Provider' })):[],
    // services: obj.services!=undefined?obj.services.map(service => ({ ...service, role: 'service' })):[],
  };
  const mergedArray = [...newObj.users, ...newObj.service_providers];
  const newArray = mergedArray.map((item, index) => {
    return {
      ...item,
      entityID: item.id,
      id: index + 1,
    };
  });
  // console.log("the filal restul",[...newObj.users, ...newObj.operators, ...newObj.shops])
  console.log("newArray",newArray)
  await setRowsSearch(newArray)
  // return [...newObj.users, ...newObj.operators, ...newObj.shops];

}

const handleSearch = () => {
  setIsLoadingModal(true)
  console.log("selectedOption",selectedOption)
  searchByUsername(filterValue,selectedOption)
  // const query = SEARCH_QUERIES[selectedOption];
  // execute the GraphQL query with search term as a variable
  // using the useQuery hook from @apollo/client
  setIsLoadingModal(false)

  setSearchMode(true)

};

const handleConfirm = () => {
  // Perform the action you want to execute here
  handleButtonClick()
  setIsModalOpen(false); // Close the modal
};

const handleCancel = () => {
  setIsModalOpen(false); // Close the modal
};

const handleClick = () => {
  if(selectedOption!=0&&selectedRows.length>0){
    setIsModalOpen(true); // Open the modal
  }
};

function transformCreditsToCurrency(credits) {
  const currency = (credits / 100).toFixed(2);
  return currency;
}





function handleSelectChange(event) {
  const value = event.target.selectedOptions[0].value;
  setSelectedOption(value);
}




const filterTable = (event, originalData, setData) => {
  setFilterValue(event.target.value);

}



const { refetch } = 
useQuery(
  DESCENDANTS_QUERY,

  {
    onError:(err) =>{
      console.log(err.message)
      // window.location.href = "/#/login"

    },
    // variables:  {"id": localStorage.getItem("opID")},
    variables:  {"id": "1"},


    // variables: {domain_id: {"_eq": locStorageItem}},
    onCompleted: async(data) => {
      console.log("TA DATA:",data)
      if(data.operators_by_pk!=null){
        // await setopTimezone(data.operators_by_pk.operator_timezone)
        // let theDescendants=data.operators_by_pk.descendants.slice(0)
        // await theDescendants.push(parseInt(localStorage.getItem("opID")))
        // await setDescendantsList(theDescendants)
        const allResponse = await client.query({
          query: COUNTERS_QUERY,
          // variables: { "_in": theDescendants },
          // fetchPolicy: 'network-only', // or 'cache-and-network'
        });        
        console.log("allResponse",allResponse)
        if (allResponse.data) {
             tileBoxs1[0].counter=allResponse.data.serviceProvidersCount.aggregate.count
        tileBoxs1[2].counter=allResponse.data.servicesCount.aggregate.count
          tileBoxs1[1].counter=allResponse.data.usersCount.aggregate.count
          tileBoxs1[3].counter= allResponse.data.appointmentsCount.aggregate.count


        }

        const appointmentslResponse = await client.query({
          query: APPOINTMENTS_QUERY
        });
        console.log("appointmentslResponse",appointmentslResponse)

        if (appointmentslResponse.data && appointmentslResponse.data.appointments) {
          setAppointments(appointmentslResponse.data.appointments);
        }

      }


      setIsLoading(false)
      setIsLoadingModal(false);

    },
    notifyOnNetworkStatusChange: true,
    // fetchPolicy: 'network-only', // or 'cache-and-network'

    // pollInterval:5000
  }
);





useEffect(() => {
  let theDescendants=localStorage.getItem("descendants")
  let theUsername=localStorage.getItem("username")

  console.log("descendants",theDescendants)
  console.log("theUsername",theUsername)

}, []);
const [toggledClearRows, setToggledClearRows] = useState(false);

const [selectedRows, setSelectedRows] = React.useState([]);

const handleClearSelection = () => {
  setSelectedRows([]);
  setToggledClearRows(!toggledClearRows);
};


	const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
        console.log("eta rows",state.selectedRows)
        console.log("et state",state)
        console.log("eta not selected",theNotSelected)

	}, []);


  


    const handleButtonClick = async () => {

    switch(selectedOption) {
      case '1':
        await handleClearSelection()
        console.log('Option 1 selected');
        break;
      case '2':
        await handleClearSelection()
        // perform action for option 2
        console.log('Option 2 selected');
        break;
      case '3':
        await handleClearSelection()
        // perform action for option 3
        console.log('Option 3 selected');
        break;
      case '4':
        await handleClearSelection()
        // perform action for option 4
        console.log('Option 4 selected');
        break;
      default:
        // do nothing
        break;
    }
    
  }
  const handleSortChange = (column, sortDirection) => {
    setSortState({ column, sortDirection });
  };
  // onClick={handleButtonClick}
  function sortData(data, sortState) {
    const { column, sortDirection } = sortState;
    if (!column || !sortDirection) return data;
  console.log("current column",column)
  console.log("current sortDirection",sortDirection)
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return sortDirection === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  
    return sortedData;
  }

  const customStyles = {
    // header: {
    //   style: {
    //     border: '1px solid #dee2e6'
    //   }
    // },
    cells: {
      style: {
        border: '0.5px solid #dee2e6',
      },
    },
  };

  function formatDate(dateString, timezone) {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: localStorage.getItem("opTimezone"),
    };
    return date.toLocaleString('en-GB', options).replace(',', '');
  }
  const columnsSearch = [
    {
        name: t("Username"),
        sortable: true,
        // width: "150px",
        sortFunction: (a, b) => a.username.localeCompare(b.username),
        selector: (cell) => {return (<Link to={cell.role == 'User' ?`/userDetails/${cell.entityID}`:cell.role === "Service Provider" ?`/shopDetails/${cell.entityID}`:'/home'} onClick={(e) => {
          console.log("cell",cell)
          e.preventDefault();
          window.location.href = cell.role == 'User' ?`/userDetails/${cell.entityID}`:cell.role === "Service Provider" ?`/shopDetails/${cell.entityID}`: '/home';
        }}
        style={{ color: cell.role == 'User' ? '' : cell.role == 'Service Provider' ? 'green' : cell.role == 'service' ? 'red' : '' }}
    
        >{cell.username}</Link>);},
        
    },
    {
      name: t("Role"),
      // width: "150px",
      selector: row => row.role,
      sortable: true
  },



];
const statusStyles = {
  COMPLETED: {
    backgroundColor: "#d4edda", // Green
    color: "#155724",
    fontWeight: "bold",
  },
  CONFIRMED: {
    backgroundColor: "#cce5ff", // Blue
    color: "#004085",
    fontWeight: "bold",
  },
  CANCELLED: {
    backgroundColor: "#f8d7da", // Red
    color: "#721c24",
    fontWeight: "bold",
  },
  PENDING: {
    backgroundColor: "#fff3cd", // Yellow
    color: "#856404",
    fontWeight: "bold",
  },
};
const columns = [
  {
    name: t("Created At"),
    selector: row => new Date(row.created_at), // Keep raw Date object
    sortable: true,
    format: row => new Date(row.created_at).toLocaleString() // Display formatted date

  },
  {
    name: t("User"),
    selector: row => row.user?.username || "N/A", // Use raw username for sorting
    cell: row => (
      <a
        href={`#/userDetails/${row.user?.id || ''}`}
        style={{ textDecoration: "none", color: "blue" }}
      >
        {row.user?.username || "N/A"}
      </a>
    ),
    sortable: true,
  },
  {
    name: t("Service"),
    selector: row => {
      const services = row.appointment_to_staff_to_service_relationship.map(
        (rel) => rel.staff_to_service_relationship.service.name
      );
      // Sort by joined service names
      return services.join(",") || "N/A";
    },
    cell: (row) => {
      const services = row.appointment_to_staff_to_service_relationship.map(
        (rel) => rel.staff_to_service_relationship.service.name
      );

      // If there's more than one service, prepend "COMB: "
      return services.length > 1 ? `COMB: ${services.join(" + ")}` : services[0] || "N/A";
    },
    sortable: true,
  },
  {
    name: t("Provider"),
    selector: row => row.appointment_to_staff_to_service_relationship[0]?.staff_to_service_relationship.service.service_provider?.username || "N/A", // Use raw provider username
    cell: row => {
      const provider = row.appointment_to_staff_to_service_relationship[0]?.staff_to_service_relationship.service.service_provider;
      return provider ? (
        <a
          href={`#/shopDetails/${provider.id || ''}`}
          style={{ textDecoration: "none", color: "blue" }}
        >
          {provider.username}
        </a>
      ) : (
        "N/A"
      );
    },
    sortable: true,
  },
  {
    name: t("Price"),
    //row.final_price
    selector: row => row.appointment_to_staff_to_service_relationship[0]?.staff_to_service_relationship.service.price || 0, // Use raw price for sorting
    cell: row => {
      const price = row.appointment_to_staff_to_service_relationship[0]?.staff_to_service_relationship.service.price;
      return price ? `$${price.toFixed(2)}` : "N/A";
    },
    sortable: true
  },
  {
    name: t("Start Time"),
    selector: row => new Date(row.start_time), // Keep raw Date object for sorting
    cell: row => new Date(row.start_time).toLocaleString(),
    sortable: true
  },
  // {
  //   name: t("End Time"),
  //   selector: row => new Date(row.end_time).toLocaleString(),
  //   sortable: true
  // },
  {
    name: t("Status"),
    selector: row => row.status,
    sortable: true,
    cell: row => {
      const style = statusStyles[row.status] || {};
      return (
        <div
          style={{
            backgroundColor: style.backgroundColor || "transparent",
            color: style.color || "inherit",
            fontWeight: style.fontWeight || "normal",
            padding: "5px 10px",
            borderRadius: "5px",
            textAlign: "center",
            width: "100%",
          }}
        >
          {row.status}
        </div>
      );
    },
  },
];
const filteredAppointments = appointments.filter((row) => {
  return columns.some((column) => {
    const selector = column.selector;
    const format = column.format;
    const cell = column.cell;

    // Check if column has a format or cell property
    if (cell) {
      const cellValue = cell(row);
      if (React.isValidElement(cellValue)) {
        // Extract the text content from React elements
        const textValue = cellValue.props?.children || "";
        return textValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
      }
      return cellValue && cellValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
    } else if (format) {
      const formattedValue = format(row);
      return formattedValue && formattedValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
    } else if (typeof selector === "function") {
      const value = selector(row);

      // Check if the value is a React element
      if (React.isValidElement(value)) {
        const textValue = value.props?.children || "";
        return textValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
      }

      // Fallback to standard value comparison
      return value && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  });
});

const rowsPerPageSample={ rowsPerPageText: t('Rows per page:'), rangeSeparatorText: t('of'), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }

  return (
    <React.Fragment>      
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Dashboard")} pageTitle={t("Home")} />

          <Row>
            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">{t("Search")}</h5>
                                </CardHeader>
                                <CardBody>

                                <Row className="g-4 mb-3">
                                            {/* <Col className="col-sm-auto"> */}
                                            <Col md="6">
                                                <div className="d-flex justify-content-md-end">
                                                    <div className="search-box sm-12" style={{"width":"300px"}} >
                                                   
                                                        <input type="text" className="form-control search" placeholder={t("Search")+"..."} value={filterValue} onChange={(e)=>{filterTable(e,initialRows,setRows)}} />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm="6" md="3">
                                                <div>
                                                <select className="form-select" aria-label=".form-select-sm example"         value={selectedOption}
 onChange={handleSelectChange}>
                                                {SEARCH_OPTIONS.map(({ value, label }) => (
          <option key={value} value={value}>{t(label)}</option>
        ))}
                                                </select>
                                                </div>
                                            </Col>
                                            
                                            {/* <Col className="col-sm-auto"> */}
                                            <Col md="3">
                                                <div>
                                              <Button color="light" onClick={handleSearch}>{t("Search")}  </Button>

                                                </div>
                                            </Col>

                                        </Row>
            {searchMode&&<DataTable
            // fixedHeader
            // fixedHeaderScrollHeight="300px"
            // fixedHeaderScrollHeight="100px"
            onSort={handleSortChange}
            striped
            // progressPending={isLoading}
            dense
            highlightOnHover
            columns={columnsSearch}
            data={rowsSearch}
            // selectableRows
            // onSelectedRowsChange={handleRowSelected}
            paginationComponentOptions={rowsPerPageSample}
            pagination
            paginationRowsPerPageOptions={[10, 15, 20,100]}
            paginationComponentOptions={rowsPerPageSample}
            clearSelectedRows={toggledClearRows}
            // style={{ borderCollapse: "collapse" }}
            // className="react-data-table"
            customStyles={customStyles}

        />}
                                </CardBody>
                            </Card>

            <Row>
                {(tileBoxs1 || []).map((item, key) => (
                    <Col xs={6} sm={3} key={key}>
                      {/* <Col xl={3} md={6} sm={3} key={key}> */}
                        <Card className={"card-animate " + item.bgColor}>
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <p className={"text-uppercase fw-medium mb-0 text-" + item.labelClass}>{t(item.label)}</p>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <h5 className={"fs-14 mb-0 text-" + item.percentageClass}>
                                            <i className={"fs-13 align-middle " + item.percentageIcon}></i> {item.percentage}
                                        </h5>
                                    </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-4">
                                    <div>
                                        <h4 className={"fs-22 fw-semibold ff-secondary mb-4 " + item.counterClass}><span className="counter-value" data-target="559.25">
                                            <CountUp
                                                start={0}
                                                prefix={item.prefix}
                                                suffix={item.suffix}
                                                separator={item.separator}
                                                end={item.counter}
                                                decimals={item.decimals}
                                                duration={3}
                                            />
                                        </span></h4>
                                        <Link to={item.label==="Users"?"/#/onlineusers":item.label==="Providers"?"/#/shops":item.label==="Operators"?"/#/operators":"#"} className={"text-decoration-underline " + item.captionClass} onClick={(e) => {
                            e.preventDefault();
                            window.location.href = item.label==="Users"?"/#/onlineusers":item.label==="Providers"?"/#/shops":item.label==="Operators"?"/#/operators":"#";
                          }}>{t(item.caption)}</Link>
                                    </div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded fs-3 bg-soft-" + item.iconClass}>
                                            <i className={item.icon + " text-" + item.iconClass}></i>
                                        </span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>))}
            </Row>
            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">{t("Appointments")}</h5>
                                </CardHeader>
                                <CardBody>
                                <Row className="mb-3">
  <Col sm="12" md="6">
    <Input
      type="text"
      placeholder={t("Search all columns")}
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className="form-control"
    />
  </Col>
</Row>
            <DataTable
            // fixedHeader
            // fixedHeaderScrollHeight="300px"
            // fixedHeaderScrollHeight="100px"
            onSort={handleSortChange}
            striped
            progressPending={isLoading}
            dense
            highlightOnHover
            columns={columns}
            // data={appointments}
            data={filteredAppointments}

            paginationComponentOptions={rowsPerPageSample}

            // selectableRows
            // onSelectedRowsChange={handleRowSelected}
            pagination
            paginationRowsPerPageOptions={[10, 15, 20,100]}
            // clearSelectedRows={toggledClearRows}
            // style={{ borderCollapse: "collapse" }}
            // className="react-data-table"
            customStyles={customStyles}

        />
                                </CardBody>
                            </Card>
            {/* <Col md={3}><h5>{t("Recent Activity")}</h5><RecentActivity logs={recentActivity} /></Col> */}
          </Row>
          

        </Container>

    <Modal
                isOpen={isLoadingModal}
                toggle={() => {
                  setIsLoadingModal();
                }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
                size='xs'
            >

                <ModalBody className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
                </ModalBody>
            </Modal>

            <Modal isOpen={isModalOpen} toggle={handleCancel}>
        <ModalHeader toggle={handleCancel}>Confirm</ModalHeader>
        <ModalBody>
          Are you sure you want {actionObj[selectedOption]} for the selected items?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirm}>
            Yes
          </Button>
          <Button color="secondary" onClick={handleCancel}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      </div>

    </React.Fragment>
  );
};

export default Starter; 