import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Breadcrumb, BreadcrumbItem, Card, CardHeader, CardBody, Col, Container, Input, Label, Row, Button, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Link, useParams } from 'react-router-dom';
import classnames from "classnames";
import { useQuery, useMutation, gql } from '@apollo/client';
import { useCreateLog } from "../../Components/Common/functions";
import UserAppointments from '../UserAppointments/userAppointments';
import Reviews from '../UserReviews/userReviews';
import { prepareLogArray } from "../../Components/Common/functions";
import LogsConsole from "../../Components/Common/LogsConsole";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const USER_QUERY = gql`
query MyQuery($id: Int!) {
  users_by_pk(id: $id) {
    username
    password
    phone_number
    first_name
    last_name
    email
    address_zip_code
    address_street
    address_state
    address_city
    address_coordinates
    gender
    is_enabled
    birthday
  }
      logs(
    where: {affected_id: {_eq: $id}, affected_type: {_eq: "user"}}
    order_by: {created_at: desc}
  ) {
    affected_id
    affected_type
    belongs_to_id
    belongs_to_type
    created_at
    description
    id
    is_success
  }
}
`;

const EDIT_USER_MUTATION = gql`
mutation MyMutation($id: Int!, $username: String!, $password: String!, $phone_number: String!, $first_name: String!, $last_name: String!, $email: String!, $address_zip_code: String!, $address_street: String!, $address_state: String!, $address_coordinates: String!, $address_city: String!, $birthday: date!, $gender: String!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: {username: $username, password: $password, phone_number: $phone_number, first_name: $first_name, last_name: $last_name, email: $email, address_zip_code: $address_zip_code, address_street: $address_street, address_state: $address_state, address_coordinates: $address_coordinates, address_city: $address_city, birthday: $birthday, gender: $gender}) {
    id
  }
}
`;

const TOGGLE_USER_MUTATION = gql`
mutation ToggleUserStatus($id: Int!, $is_enabled: Boolean!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: {is_enabled: $is_enabled}) {
    id
    is_enabled
  }
}
`;

const UserDetails = () => {
  const { t } = useTranslation();
  const { createLog } = useCreateLog();
  const { userId } = useParams();
  const [activeTab, setActiveTab] = useState("1");
  const [userDetails, setUserDetails] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [mutationSuccess, setMutationSuccess] = useState(false);
  const [modal, setModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Add this state variable
  const [logsArray, setLogsArray] = useState([]);


  const toggleModal = () => setModal(!modal);

  console.log('Component initialized');

  const { loading, error, data, refetch } = useQuery(USER_QUERY, {
    variables: { id: parseInt(userId) },
    onCompleted: (data) => {
      console.log('Data fetched:', data);
      setUserDetails(data.users_by_pk);
      prepareLogArray(data.logs)
      .then(transformedLogs => {
       setLogsArray(transformedLogs)
        console.log("transformedLogs",transformedLogs);
      })
      .catch(err => {
        console.error("transformedLogs",err);
      });
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    }
  });

  const [editUserMutation] = useMutation(EDIT_USER_MUTATION, {
    onCompleted: (data) => {
      console.log('Edit mutation completed:', data);
      createLog(parseInt(userId), "user", "Details Edited", true);
      setMutationSuccess(true);
    },
    onError: (error) => {
      console.error('Error in edit mutation:', error);
      createLog(parseInt(userId), "user", "Details Edited", false);
    },
  });

  const [toggleUserMutation] = useMutation(TOGGLE_USER_MUTATION, {
    onCompleted: () => {
      createLog(parseInt(userId), "user",userDetails.is_enabled ?"User Disabled":"User Enabled" , true);
      refetch();
    },
    onError: (error) => {
      createLog(parseInt(userId), "user",userDetails.is_enabled ?"User Disabled":"User Enabled" , false);
      console.error('Error toggling user status:', error);
    }
  });

  const handleSaveDetailsClick = async () => {
    const { username, password, phone_number, first_name, last_name, email, address_zip_code, address_street, address_state, address_coordinates, address_city, birthday, gender } = userDetails;
    if (!username || !password || !phone_number || !first_name || !last_name || !email || !address_zip_code || !address_street || !address_state || !address_coordinates || !address_city || !birthday || !gender) {
      alert("All fields are required.");
      return;
    }
    try {
      await editUserMutation({
        variables: {
          id: parseInt(userId),
          username,
          password,
          phone_number,
          first_name,
          last_name,
          email,
          address_zip_code,
          address_street,
          address_state,
          address_coordinates,
          address_city,
          birthday,
          gender,
        },
      });
    } catch (error) {
      console.error('Error in handleSaveDetailsClick:', error);
    }
  };

  const handleToggleUserStatus = async () => {
    try {
      await toggleUserMutation({
        variables: {
          id: parseInt(userId),
          is_enabled: !userDetails.is_enabled,
        },
      });
    } catch (error) {
      console.error('Error toggling user status:', error);
    }
    toggleModal()
  };

  useEffect(() => {
    if (mutationSuccess) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      setMutationSuccess(false);
    }
  }, [mutationSuccess]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  console.log('Rendering component with details:', userDetails);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Users")} pageTitle={t("Home")} />
          {showSuccessMessage && (
            <div className="alert alert-success text-center" role="alert">
              Success!
            </div>
          )}
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                <h5 className="card-title mb-0">
                    {t("User")} - {userDetails.username}{" "}
                    <span style={{ color: userDetails.is_enabled ? "green" : "red" }}>
                      {userDetails.is_enabled ? "" : "(Disabled)"}
                    </span>
                  </h5>                </CardHeader>
                <CardBody>
                  <Nav tabs className="nav-tabs mb-3">
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1" })} onClick={() => { toggle("1"); }}>
                        {t("Details")}
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "2" })} onClick={() => { toggle("2"); }}>
                        {t("Appointments")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "3" })} onClick={() => { toggle("3"); }}>
                        {t("Reviews")}
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "2" })} onClick={() => { toggle("2"); }}>
                        {t("History")}
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                      <Card>
                        <CardBody>
                          <form action="#">
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="usernameInput" className="form-label">{t("Username")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="usernameInput" value={userDetails.username || ''} onChange={(e) => setUserDetails({ ...userDetails, username: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="passwordInput" className="form-label">{t("Password")}</Label>
                              </Col>
                              <Col lg={6}>
                                <div className="input-group">
                                  <Input type={showPassword ? "text" : "password"} className="form-control" id="passwordInput" value={userDetails.password || ''} onChange={(e) => setUserDetails({ ...userDetails, password: e.target.value })} />
                                  <button type="button" className="btn btn-outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? "Hide" : "Show"}
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="emailInput" className="form-label">{t("Email")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="email" className="form-control" id="emailInput" value={userDetails.email || ''} onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="phoneInput" className="form-label">{t("Phone Number")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="phoneInput" value={userDetails.phone_number || ''} onChange={(e) => setUserDetails({ ...userDetails, phone_number: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="firstNameInput" className="form-label">{t("First Name")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="firstNameInput" value={userDetails.first_name || ''} onChange={(e) => setUserDetails({ ...userDetails, first_name: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="lastNameInput" className="form-label">{t("Last Name")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="lastNameInput" value={userDetails.last_name || ''} onChange={(e) => setUserDetails({ ...userDetails, last_name: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="coordinatesInputLat" className="form-label">{t("Coordinates")}</Label>
                              </Col>
                              <Col lg={3}>
                                <Input type="text" className="form-control" id="coordinatesInputLat" placeholder="Latitude" value={userDetails.address_coordinates ? userDetails.address_coordinates.split(',')[0] : ''} onChange={(e) => {
                                  const coords = userDetails.address_coordinates ? userDetails.address_coordinates.split(',') : ['', ''];
                                  coords[0] = e.target.value;
                                  setUserDetails({ ...userDetails, address_coordinates: coords.join(',') });
                                }} />
                              </Col>
                              <Col lg={3}>
                                <Input type="text" className="form-control" id="coordinatesInputLon" placeholder="Longitude" value={userDetails.address_coordinates ? userDetails.address_coordinates.split(',')[1] : ''} onChange={(e) => {
                                  const coords = userDetails.address_coordinates ? userDetails.address_coordinates.split(',') : ['', ''];
                                  coords[1] = e.target.value;
                                  setUserDetails({ ...userDetails, address_coordinates: coords.join(',') });
                                }} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="addressStreetInput" className="form-label">{t("Street Address")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="addressStreetInput" value={userDetails.address_street || ''} onChange={(e) => setUserDetails({ ...userDetails, address_street: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="addressCityInput" className="form-label">{t("City")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="addressCityInput" value={userDetails.address_city || ''} onChange={(e) => setUserDetails({ ...userDetails, address_city: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="addressStateInput" className="form-label">{t("State")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="addressStateInput" value={userDetails.address_state || ''} onChange={(e) => setUserDetails({ ...userDetails, address_state: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="addressZipCodeInput" className="form-label">{t("Zip Code")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="addressZipCodeInput" value={userDetails.address_zip_code || ''} onChange={(e) => setUserDetails({ ...userDetails, address_zip_code: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                          <Col md={3}>
                            <Label>{t("Birthday")}</Label>
                          </Col>
                          <Col lg={6}>
                            <DatePicker
                              selected={userDetails.birthday ? new Date(userDetails.birthday) : null}
                              onChange={(date) => setUserDetails({ ...userDetails, birthday: date.toISOString().split('T')[0] })}
                              className="form-control"
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={3}>
                            <Label>{t("Gender")}</Label>
                          </Col>
                          <Col lg={6}>
                            <Input
                              type="select"
                              value={userDetails.gender || ''}
                              onChange={(e) => setUserDetails({ ...userDetails, gender: e.target.value })}
                            >
                              <option value="Male">{t("Male")}</option>
                              <option value="Female">{t("Female")}</option>
                              <option value="Other">{t("Other")}</option>
                            </Input>
                          </Col>
                        </Row>
                            <div className="text-end">
                              <Button type="button" className="btn btn-primary" onClick={handleSaveDetailsClick}>{t("Save")}</Button>
                            </div>
                          </form>
                          {showSuccessMessage && (
            <div className="alert alert-success text-center" role="alert">
              Success!
            </div>
          )}
                        </CardBody>
                      </Card>
                      <div className="text-end">
                      {/* <Button type="button" className="btn btn-light" onClick={handleToggleUserStatus}>
                          {userDetails.is_enabled ? t("Disable this User") : t("Enable this User")}
                        </Button>                         */}
                        <Button type="submit" className="btn btn-light" onClick={toggleModal}>{userDetails.is_enabled ? t("Disable this User") : t("Enable this User")}</Button>
                        <Modal backdrop="static" isOpen={modal} toggle={toggle}>
                          <ModalHeader toggle={toggleModal}>{t("Confirm")} {userDetails.is_enabled ?"Disable":"Enable"}</ModalHeader>
                          <ModalBody>
                            {userDetails.is_enabled ?"Are you sure you want to disable this user?":"Are you sure you want to enable this user?"}
                          </ModalBody>
                          <ModalFooter>
                            <Button color="primary" onClick={handleToggleUserStatus}>Yes</Button>{' '}
                            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                    <UserAppointments customer_user_id={parseInt(userId)}/>
                    </TabPane>
                    <TabPane tabId="3">
                    <Reviews customer_user_id={parseInt(userId)}/>

                    
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">{t("Logs")}</h5>
                                </CardHeader>
                                <CardBody><LogsConsole logs={logsArray} /></CardBody>
                                

                            </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserDetails;
