import React from 'react';
import styled from 'styled-components';

const ConsoleContainer = styled.div`
  background-color: black;
  color: white;
  padding: 10px;
  font-family: 'Courier New', Courier, monospace;
`;

const LogLine = styled.div`
  margin-bottom: 5px;
`;

const FromEntity = styled.span`
  color: lime;
`;

const Time = styled.span`
  color: white;
`;

const Description = styled.span`
  color: red;
`;

const Success = styled.span`
  color: ${props => props.success ? 'lime' : 'red'};
`;

const LogsConsole = ({ logs }) => {
  return (
    <ConsoleContainer>
      {logs.map((log, index) => (
        <LogLine key={index}>
          <FromEntity>{log.fromEntity} </FromEntity>
          <Time>{log.time} </Time>
          <Description>{log.description} </Description>
          <Success success={log.success === 'successfully'}>
            {log.success}
          </Success>
        </LogLine>
      ))}
    </ConsoleContainer>
  );
};

export default LogsConsole;
