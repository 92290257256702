import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Card, CardHeader, CardBody, Col, Container, Row,
  Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Input
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';

const SERVICE_PROVIDERS_QUERY = gql`
query GetServiceProviders {
  service_providers(order_by: {id: asc}) {
    id
    username
    description
    phone
    email
    address_city
  }
}
`;

const ENABLE_SERVICE_PROVIDER_MUTATION = gql`
mutation EnableServiceProvider($isEnabled: Boolean!, $ids: [Int!]) {
  update_service_providers(where: {id: {_in: $ids}}, _set: {is_enabled: $isEnabled}) {
    affected_rows
  }
}
`;

const Starter = () => {
  const { t } = useTranslation();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { loading, error, data } = useQuery(SERVICE_PROVIDERS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.service_providers) {
        const query = new URLSearchParams(window.location.hash.split('?')[1]);
        const searchQuery = query.get('search'); // Look for 'search' parameter
        const initialData = searchQuery
          ? data.service_providers.filter(provider =>
              provider.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
              provider.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
              provider.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
              provider.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
              provider.address_city.toLowerCase().includes(searchQuery.toLowerCase())
            )
          : data.service_providers;

        setFilteredData(initialData);
        setSearchTerm(searchQuery || '');
      }
    },
    onError: (error) => {
      console.error("Error fetching service providers:", error.message);
    }
  });

  useEffect(() => {
    if (data && data.service_providers) {
      setFilteredData(data.service_providers);
    }
  }, [data]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = data.service_providers.filter(provider => 
      provider.username.toLowerCase().includes(value) ||
      provider.description.toLowerCase().includes(value) ||
      provider.phone.toLowerCase().includes(value) ||
      provider.email.toLowerCase().includes(value) ||
      provider.address_city.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  const columns = [
    {
      name: t("Username"),
      sortable: true,
      sortFunction: (a, b) => a.username.localeCompare(b.username),
      selector: (cell) => (
        <Link to={`/shopDetails/${cell.id}`} onClick={(e) => {
          e.preventDefault();
          window.location.href = `/#/shopDetails/${cell.id}`;
        }}>
          {cell.username}
        </Link>
      ),
    },
    {
      name: t("Description"),
      selector: row => row.description,
      sortable: true,
    },
    {
      name: t("Phone"),
      selector: row => row.phone,
      sortable: true,
    },
    {
      name: t("Email"),
      selector: row => row.email,
      sortable: true,
    },
    {
      name: t("City"),
      selector: row => row.address_city,
      sortable: true,
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Service Providers")} pageTitle={t("Home")} />
          <Card>
            <CardBody>
              <Row className="g-4 mb-3">
                <Col md="3"></Col>
                <Col md="3"></Col>
                <Col md="6">
                  <div className="d-flex justify-content-md-end">
                    <div className="search-box sm-12" style={{ "width": "300px" }}>
                      <input 
                        type="text" 
                        className="form-control search" 
                        placeholder={t("Search...")} 
                        value={searchTerm} 
                        onChange={handleSearch} 
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </Col>
              </Row>
              <DataTable
                columns={columns}
                data={filteredData}
                progressPending={loading}
                pagination
                highlightOnHover
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Starter;
