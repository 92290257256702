import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";import { Card,CardHeader, CardBody, Col, Container, Input, Label, Row, Table,Button,Modal, ModalBody, ModalHeader,ModalFooter, Spinner} from 'reactstrap';
//import { BasicTable, ScrollVertical, ScrollHorizontal, AlternativePagination, FixedHeaderDatatables, ModalDataDatatables, AjaxDatatables } from '../Tables/DataTables/datatableCom';
import { useQuery,useMutation, gql } from '@apollo/client';
// import checkTokenValidity from '../../Layouts/Footer'
import DataTable from 'react-data-table-component';


const APPOINTMENTS_QUERY = gql`
query GetAppointments($_eq: Int = 10) {
  appointments(
    order_by: {created_at: desc}
    where: {appointment_to_staff_to_service_relationship: {staff_to_service_relationship: {service: {service_provider_id: {_eq: $_eq}}}}}
  ) {
    id
    created_at
    start_time
    end_time
    status
    user {
    id
      username
    }
    appointment_to_staff_to_service_relationship {
      staff_to_service_relationship {
        service {
          id
          name
          price
          service_provider {
            username
          }
        }
      }
    }
  }
}

`;










const ShopAppointments = ({serviceProviderId}) => {
  const { t } = useTranslation();
document.title ="Home";



const [isLoading, setIsLoading] = useState(true)
const [sortState, setSortState] = useState({});

const [appointments, setAppointments] = useState([]);
const [searchTerm, setSearchTerm] = useState("");








const { refetch } = 
useQuery(
    APPOINTMENTS_QUERY,

  {
    onError:(err) =>{
      console.log(err.message)
      // window.location.href = "/#/login"

    },
    variables:  {"_eq": parseInt(serviceProviderId)},

    onCompleted: async(data) => {
      console.log("TA DATA:",data)

        if (data && data.appointments) {
          setAppointments(data.appointments);
        }

      


      setIsLoading(false)

    },
    notifyOnNetworkStatusChange: true,
    // fetchPolicy: 'network-only', // or 'cache-and-network'

    // pollInterval:5000
  }
);





useEffect(() => {
  let theDescendants=localStorage.getItem("descendants")
  let theUsername=localStorage.getItem("username")

  console.log("descendants",theDescendants)
  console.log("theUsername",theUsername)

}, []);

  const customStyles = {

    cells: {
      style: {
        border: '0.5px solid #dee2e6',
      },
    },
  };
  const statusStyles = {
    COMPLETED: {
      backgroundColor: "#d4edda", // Green
      color: "#155724",
      fontWeight: "bold",
    },
    CONFIRMED: {
      backgroundColor: "#cce5ff", // Blue
      color: "#004085",
      fontWeight: "bold",
    },
    CANCELLED: {
      backgroundColor: "#f8d7da", // Red
      color: "#721c24",
      fontWeight: "bold",
    },
    PENDING: {
      backgroundColor: "#fff3cd", // Yellow
      color: "#856404",
      fontWeight: "bold",
    },
  };

const columns = [
  {
    name: t("Created At"),
    selector: row => new Date(row.created_at), // Keep raw Date object
    sortable: true,
    format: row => new Date(row.created_at).toLocaleString() // Display formatted date

  },
  {
    name: t("User"),
    selector: row => row.user?.username || "N/A", // Use raw username for sorting
    cell: row => (
      <a
        href={`#/userDetails/${row.user?.id || ''}`}
        style={{ textDecoration: "none", color: "blue" }}
      >
        {row.user?.username || "N/A"}
      </a>
    ),
    sortable: true,
  },
  {
    name: t("Service"),
    selector: row => {
      const services = row.appointment_to_staff_to_service_relationship.map(
        (rel) => rel.staff_to_service_relationship.service.name
      );
      // Sort by joined service names
      return services.join(",") || "N/A";
    },
    cell: (row) => {
      const services = row.appointment_to_staff_to_service_relationship.map(
        (rel) => rel.staff_to_service_relationship.service.name
      );

      // If there's more than one service, prepend "COMB: "
      return services.length > 1 ? `COMB: ${services.join(" + ")}` : services[0] || "N/A";
    },
    sortable: true,
  },
//   {
//     name: t("Provider"),
//     selector: row => 
//       row.appointment_to_staff_to_service_relationship[0]?.staff_to_service_relationship.service.service_provider.username || "N/A",
//     sortable: true
//   },
{
  name: t("Price"),
  //row.final_price
  selector: row => row.appointment_to_staff_to_service_relationship[0]?.staff_to_service_relationship.service.price || 0, // Use raw price for sorting
  cell: row => {
    const price = row.appointment_to_staff_to_service_relationship[0]?.staff_to_service_relationship.service.price;
    return price ? `$${price.toFixed(2)}` : "N/A";
  },
  sortable: true
},
{
  name: t("Start Time"),
  selector: row => new Date(row.start_time), // Keep raw Date object for sorting
  cell: row => new Date(row.start_time).toLocaleString(),
  sortable: true
},

{
  name: t("Status"),
  selector: row => row.status,
  sortable: true,
  cell: row => {
    const style = statusStyles[row.status] || {};
    return (
      <div
        style={{
          backgroundColor: style.backgroundColor || "transparent",
          color: style.color || "inherit",
          fontWeight: style.fontWeight || "normal",
          padding: "5px 10px",
          borderRadius: "5px",
          textAlign: "center",
          width: "100%",
        }}
      >
        {row.status}
      </div>
    );
  },
},
];
const filteredAppointments = appointments.filter((row) => {
  return columns.some((column) => {
    const selector = column.selector;
    const format = column.format;
    const cell = column.cell;

    // Check if column has a format or cell property
    if (cell) {
      const cellValue = cell(row);
      if (React.isValidElement(cellValue)) {
        // Extract the text content from React elements
        const textValue = cellValue.props?.children || "";
        return textValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
      }
      return cellValue && cellValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
    } else if (format) {
      const formattedValue = format(row);
      return formattedValue && formattedValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
    } else if (typeof selector === "function") {
      const value = selector(row);

      // Check if the value is a React element
      if (React.isValidElement(value)) {
        const textValue = value.props?.children || "";
        return textValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
      }

      // Fallback to standard value comparison
      return value && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  });
});
const rowsPerPageSample={ rowsPerPageText: t('Rows per page:'), rangeSeparatorText: t('of'), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }

  return (
    <React.Fragment>      
        <Container fluid>

          <Row>


    
            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">{t("Appointments")}</h5>
                                </CardHeader>
                                <CardBody>
                                <Row className="mb-3">
  <Col sm="12" md="6">
    <Input
      type="text"
      placeholder={t("Search all columns")}
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className="form-control"
    />
  </Col>
</Row>
            <DataTable

            striped
            progressPending={isLoading}
            dense
            highlightOnHover
            columns={columns}
            data={filteredAppointments}
            paginationComponentOptions={rowsPerPageSample}

            pagination
            paginationRowsPerPageOptions={[10, 15, 20,100]}

            customStyles={customStyles}

        />
                                </CardBody>
                            </Card>
          </Row>
          

        </Container>


    </React.Fragment>
  );
};

export default ShopAppointments; 