import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupText
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useMutation, gql } from "@apollo/client";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import L from "leaflet";

// Import marker icons explicitly
import markerIcon from "../../assets/images/giftbox.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

// Custom marker icon
const customIcon = L.icon({
  iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [25, 41], // Default size
  iconAnchor: [12, 41], // Default anchor
});


const INSERT_SERVICE_PROVIDER_MUTATION = gql`
mutation InsertServiceProvider($input: [service_providers_insert_input!]!) {
  insert_service_providers(objects: $input) {
    affected_rows
  }
}
`;


const LocationPicker = ({ setFormData }) => {
  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setFormData((prev) => ({ ...prev, latitude: lat.toFixed(6), longitude: lng.toFixed(6) }));
  };

  useMapEvents({
    click: handleMapClick,
  });

  return null;
};

const NewServiceProvider = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    phone: "",
    contact_email: "-",
    business_email: "-",
    description: "",
    address_street: "",
    address_city: "",
    address_state: "",
    address_zip_code: "",
    latitude: "",
    longitude: "",
  });
  const [errors, setErrors] = useState({});

  const [insertServiceProvider, { loading, error }] = useMutation(INSERT_SERVICE_PROVIDER_MUTATION, {
    onCompleted: data => {
      alert("Service provider created successfully!");
      setFormData({
        username: "",
        email: "",
        password: "",
        phone: "",
        contact_email: "-",
        business_email: "-",
        description: "",
        address_street: "",
        address_city: "",
        address_state: "",
        address_zip_code: "",
        latitude: "",
        longitude: "",
      });
    },
    onError: err => {
      alert("Failed to create service provider. Error: " + err.message);
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    Object.entries(formData).forEach(([key, value]) => {
      if (!value) {
        newErrors[key] = "This field is required";
      } else if ((key === 'email') && !emailRegex.test(value)) {
        newErrors[key] = "Invalid email format";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    const inputData = {
      ...formData,
      address_coordinates: `Latitude: ${formData.latitude}, Longitude: ${formData.longitude}`,
      parent_operator_id: 1,
    };
    delete inputData.latitude;
    delete inputData.longitude;

    insertServiceProvider({
      variables: { input: [inputData] }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("New Service Provider")} pageTitle={t("Service Providers")} />
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row >
                  <Col md={6}>
                    <Label htmlFor="username">{t("Username")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-user-fill"></i>
                      </InputGroupText>
                      <Input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        invalid={!!errors.username}
                      />
                      <FormFeedback>{t(errors.username)}</FormFeedback>
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Label htmlFor="password">{t("Password")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-lock-password-fill"></i>
                      </InputGroupText>
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        invalid={!!errors.password}
                      />
                      <FormFeedback>{t(errors.password)}</FormFeedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row style={{"marginTop":"20px"}}>
                  <Col md={6}>
                    <Label htmlFor="email">{t("Email")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-mail-fill"></i>
                      </InputGroupText>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        invalid={!!errors.email}
                      />
                      <FormFeedback>{t(errors.email)}</FormFeedback>
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Label htmlFor="phone">{t("Phone")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-phone-fill"></i>
                      </InputGroupText>
                      <Input
                        type="text"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        invalid={!!errors.phone}
                      />
                      <FormFeedback>{t(errors.phone)}</FormFeedback>
                    </InputGroup>
                  </Col>
                </Row>

                {/* <Row>
                  <Col md={6}>
                    <Label htmlFor="contact_email">{t("Contact Email")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-mail-fill"></i>
                      </InputGroupText>
                      <Input
                        type="email"
                        id="contact_email"
                        name="contact_email"
                        value={formData.contact_email}
                        onChange={handleChange}
                        invalid={!!errors.contact_email}
                      />
                      <FormFeedback>{t(errors.contact_email)}</FormFeedback>
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Label htmlFor="business_email">{t("Business Email")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-mail-fill"></i>
                      </InputGroupText>
                      <Input
                        type="email"
                        id="business_email"
                        name="business_email"
                        value={formData.business_email}
                        onChange={handleChange}
                        invalid={!!errors.business_email}
                      />
                      <FormFeedback>{t(errors.business_email)}</FormFeedback>
                    </InputGroup>
                  </Col>
                </Row> */}
                <Row style={{"marginTop":"20px"}}>
                <Col md={12}>
                    <Label htmlFor="description">{t("Description")}</Label>
                    <InputGroup>
                      <InputGroupText>
                        <i className="ri-file-text-fill"></i>
                      </InputGroupText>
                      <Input
                        type="text"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        invalid={!!errors.description}
                      />
                      <FormFeedback>{t(errors.description)}</FormFeedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row style={{"marginTop":"20px"}}>
                  <Col md={3}>
                    <Label htmlFor="address_street">{t("Street")}</Label>
                    <Input
                      type="text"
                      id="address_street"
                      name="address_street"
                      value={formData.address_street}
                      onChange={handleChange}
                      invalid={!!errors.address_street}
                    />
                    <FormFeedback>{t(errors.address_street)}</FormFeedback>
                  </Col>
                  <Col md={3}>
                    <Label htmlFor="address_city">{t("City")}</Label>
                    <Input
                      type="text"
                      id="address_city"
                      name="address_city"
                      value={formData.address_city}
                      onChange={handleChange}
                      invalid={!!errors.address_city}
                    />
                    <FormFeedback>{t(errors.address_city)}</FormFeedback>
                  </Col>
                  <Col md={3}>
                    <Label htmlFor="address_state">{t("State")}</Label>
                    <Input
                      type="text"
                      id="address_state"
                      name="address_state"
                      value={formData.address_state}
                      onChange={handleChange}
                      invalid={!!errors.address_state}
                    />
                    <FormFeedback>{t(errors.address_state)}</FormFeedback>
                  </Col>
                  <Col md={3}>
                    <Label htmlFor="address_zip_code">{t("Zip Code")}</Label>
                    <Input
                      type="text"
                      id="address_zip_code"
                      name="address_zip_code"
                      value={formData.address_zip_code}
                      onChange={handleChange}
                      invalid={!!errors.address_zip_code}
                    />
                    <FormFeedback>{t(errors.address_zip_code)}</FormFeedback>
                  </Col>
                </Row>
                <Row style={{"marginTop":"20px"}}>
                  <Col md={6}>
                    <Label htmlFor="latitude">{t("Latitude")}</Label>
                    <Input
                      type="text"
                      id="latitude"
                      name="latitude"
                      value={formData.latitude}
                      onChange={handleChange}
                      invalid={!!errors.latitude}
                    />
                    <FormFeedback>{t(errors.latitude)}</FormFeedback>
                  </Col>
                  <Col md={6}>
                    <Label htmlFor="longitude">{t("Longitude")}</Label>
                    <Input
                      type="text"
                      id="longitude"
                      name="longitude"
                      value={formData.longitude}
                      onChange={handleChange}
                      invalid={!!errors.longitude}
                    />
                    <FormFeedback>{t(errors.longitude)}</FormFeedback>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
  <Col sm={12}>
    <Label>{t("Select Location on Map")}</Label>
    <MapContainer
      center={[parseFloat(formData.latitude) || 34.684181, parseFloat(formData.longitude) || 33.032112]} // Default Nicosia
      zoom={13}
      style={{ height: "400px", width: "100%" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker
        position={[parseFloat(formData.latitude) || 34.684181, parseFloat(formData.longitude) || 33.032112]}
        icon={customIcon}      />
      <LocationPicker setFormData={setFormData} />
    </MapContainer>
  </Col>
</Row>
                <div className="text-end mt-4">
                  <Button color="primary" type="submit" disabled={loading}>
                    {t("Create")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewServiceProvider;
